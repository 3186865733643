// MTS - Disabling eslint on this file because we do not need alphabetizing
/* eslint-disable */
export const sizes = {
  s: '375px',
  md: '600px',
  lg: '900px',
  xl: '1024px',
  xxl: '1200px',
  xxxl: '1440px',
};

// Reusable media queries for Styled Components
export const media = {
  // small
  s: `@media screen and (max-width: ${sizes.s})`,
  // not small
  sm: `@media screen and (max-width: ${sizes.md})`,
  // not small
  ns: `@media screen and (min-width: ${sizes.md})`,
  // medium
  md: `@media screen and (min-width: ${sizes.md}) and (max-width: ${sizes.lg})`,
  // large
  lg: `@media screen and (min-width: ${sizes.lg})`,
  // not large
  nlg: `@media screen and (max-width: ${sizes.lg})`,
  // extra large
  xl: `@media screen and (min-width: ${sizes.xl})`,
  // not extra large
  nxl: `@media screen and (max-width: ${sizes.xl})`,
  // xxlarge
  xxl: `@media screen and (min-width: ${sizes.xxl})`,
  // xxxlarge
  xxxl: `@media screen and (min-width: ${sizes.xxxl})`,
};

export const colors = {
  background: 'white',
  black: '#2d343c',
  boxShadow: 'rgba(128, 126, 121, 0.12)',
  deepBlue: '#05133F',
  darkBlack: '#102542',
  lightBlack: '#353c45',
  red: '#e44852',
  white: 'white',
  transparent: 'transparent',
  trueBlack: '#000',
  alabaster: '#FCFCFC',
  trueWhite: '#ffffff',
  yellow: '#FF8C05',

  // Impactive
  blue: '#23293E',
  newBlue: '#90B9F6',
  teal: '#6ccbda',
  darkTeal: '#55AEBB',
  coral: '#f3786c',
  link: '#f3786c',
  darkBlue: '#23293E',
  borderGray: '#EDEDED',
  lightGray: '#FAFAFA',
  activityBackground: '#FAFAFA',
  messageBackground: '#C2DEE2',
  whiteSmoke: '#F4F4F4',
  whiteSmokeLighter: '#F8F8F8',
  lightCyan: '#e0f8fa',
  // grays
  mediumLightGray: '#DCDCDC',
  mediumGray: '#A1A1A1',
  darkGray: '#979797',
  gray: '#71717D',
  dimGray: '#666666',
  dimGray2: '#717171',
  dimGray3: '#c2c4ca',
  darksLateGray: '#272727',
  green: '#84BE77',
  cyan: '#96D1DC',
  dodgerBlue: '#40a9ff',
  blackL32: '#525252',

  // New design default colors
  blackL25: '#252525',
  salmon: '#ff786a',
  lightBlue: '#f0f9fb',

  /**
   * Don't add to this without adding to our design library
   */
  // NEW PALETTE

  // BLUE
  blue: '#23293f',
  blue60: '#7b7f8b',
  blue30: '#bdbfc5',
  blue16: '#dcdde0',
  blue06: '#f2f2f3',

  // CORAL

  coral: '#F3786C',
  coral60: '#f8aea7',
  coral40: '#fac9c4',
  coral20: '#fde4e2',
  coral10: '#fef1f0',

  // TEAL

  teal: '#6CCBDA',
  teal60: '#a7e0e9',
  teal40: '#c4eaf0',
  teal20: '#e2f5f8',
  teal10: '#f0fafb',

  // BLACK

  black: '#222222',
  blackL32: '#525252',
  blackL42: '#707070',
  blackL72: '#B7B7B7',

  // GRAY

  gray: '#d9d9d9',
  whiteSmoke: '#F5F6F7',
  snow: '#fafafa',
};

// Make sure you update app/javascript/admin/styles/theme.js formDefaultColors when editing these colors
export const formDefaultColors = {
  pageBackgroundColor: '#FCFCFC',
  sectionBackgroundColor: '#FCFCFC',
  buttonColor: '#23293e',
  fontColor: '#222222',
  buttonFontColor: '#ffffff',
  fieldOutlineColor: '#d9d9d9',
  fieldBackgroundColor: '#fcfcfc',
  fieldFontColor: '#222222',
  placeHolderColor: '#B7B7B7',
};

export const vrDefaultColors = {
  progressBarColor: '#F3786C',
  buttonColor: '#23293E',
  backgroundColor: '#FCFCFC',
};

export const fonts = {
  black: 'BasicSans-Black',
  blackItalic: 'BasicSans-BlackItalic',
  bold: 'BasicSans-Bold',
  boldItalic: 'BasicSans-BoldItalic',
  italic: 'BasicSans-Italic',
  light: 'BasicSans-Light',
  regular: 'BasicSans-Regular',
  semibold: 'BasicSans-SemiBold',
  semibolditalic: 'BasicSans-SemiboldItalic',
  AccelerateChange: 'Manrope', // this will be used only in a few pages, and has correctly imported font weights, thats why there is only one
};

export default {
  colors,
  fonts,
  formDefaultColors,
};
